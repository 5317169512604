import { orgRealm } from ".";
const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1`;
const teamsManagementBaseUrl = `${baseUrl}/teams-management`;
const platformManagement = `${baseUrl}/platform-management`;
const masterPlatformManagement = `${baseUrl}/platform-management/master`;
const nudgeService = `${baseUrl}/nudge-service/master`;
const integrationService = `${baseUrl}/integration-service/master`;
export const setDeviceToken = (deviceId) => `${baseUrl}/notifications/devices/${deviceId}`;
export const s3FileUpload = `${teamsManagementBaseUrl}/files`;

// Template Theme
export const GET_TEMPLATE_THEME_API = `${platformManagement}/themes/selected`;

//nudge service api list
export const GET_NUDGES_CATEGORY_LIST = () => `${nudgeService}/categories`;
export const ADD_NUDGES_CATEGORY = () => `${nudgeService}/categories`;
export const UPDATE_NUDGES_CATEGORY = (categoryId) => `${nudgeService}/categories/${categoryId}`;
export const GET_MASTER_EVENTS_LIST = () => `${nudgeService}/events`;
export const ADD_MASTER_EVENTS = () => `${nudgeService}/events`;
export const UPDATE_MASTER_EVENTS = () => `${nudgeService}/events`;
export const UPDATE_MASTER_EVENTS_STATUS = (eventId) => `${nudgeService}/events/${eventId}/status`;
export const ATTRIBUTES_LIST = () => `${nudgeService}/attributes`;
export const UPDATE_ATTRIBUTE = (attributeId) => `${nudgeService}/attributes/${attributeId}/status-update`;
export const GET_ATTRIBUTE_KEYS = () => `${nudgeService}/attributes/keys`
export const GET_CATEGORY_ATRIBUTES = (categoryId) => `${nudgeService}/categories/${categoryId}/attributes`;

//League Industries
export const GET_LEAGUE_INDUSTRIES = `${platformManagement}/industries`;
export const UPDATE_LEAGUE_INDUSTRIES = (industryId) => `${platformManagement}/industries/${industryId}`;
export const UPDATE_LEAGUE_INDUSTRIES_STATUS = (industryId) => `${platformManagement}/industries/${industryId}/status`

//League Functions 
export const GET_LEAGUE_FUNCTIONS = `${platformManagement}/functions`;
export const UPDATE_LEAGUE_FUNCTIONS = (functionId) => `${platformManagement}/functions/${functionId}`;
export const UPDATE_LEAGUE_FUNCTIONS_STATUS = (functionId) => `${platformManagement}/functions/${functionId}/status`

//master kpis
export const GET_KPI_LIST = () => `${platformManagement}/kpis`;
export const CREATE_KPI = () => `${platformManagement}/kpis`;
export const ROLES = () => `${platformManagement}/organizations/roles`;
export const UPDATE_KPI = (kpiId) => `${platformManagement}/kpis/${kpiId}`;
export const UPDATE_KPI_STATUS = (kpiId) => `${platformManagement}/kpis/${kpiId}/status`;
export const GET_INDUSTRIES_LIST = () => `${platformManagement}/industries`;
export const GET_FUNCTIONS_LIST = () => `${platformManagement}/functions`;
export const GET_FUNCTION_KPIS = (industryId) => `${platformManagement}/functions/${industryId}`;


// -----templates----
export const MASTER_TEMPLATES_LIST = () => `${nudgeService}/templates`;
export const MASTER_TEMPLATE_UPDATE = (tempId) => `${nudgeService}/templates/${tempId}`;
export const MASTER_TEMPLATE_STATUS = (tempId) => `${nudgeService}/templates/${tempId}/status`;
export const GET_CATEGORY_EVENTS = (categoryId) => `${nudgeService}/categories/${categoryId}/events`;
export const UPDATE_MASTER_EVENT = (eventId) => `${nudgeService}/events/${eventId}`
export const MASTER_TEMPLATE_ATTRIBUTES = (categoryId) => `${nudgeService}/categories//${categoryId}/template-attributes`

// ---object & keys -----
export const GET_OBJECTS = `${masterPlatformManagement}/objects`;
export const GET_OBJECT_DETAILS = (objId) => `${masterPlatformManagement}/objects/${objId}`;
export const OBJECT_UPDATE = (objId) => `${masterPlatformManagement}/objects/${objId}`;
export const GET_OBJECT_ELEMENTS = (objId) => `${masterPlatformManagement}/objects/${objId}/elements`;
export const UPDATE_OBJECT_ELEMENTS = (objId, eleID) => `${masterPlatformManagement}/objects/${objId}/elements/${eleID}`;

export const GET_COUNTRIES = `${platformManagement}/countries`;

//---- master-language
export const GET_LANGUAGE = `${platformManagement}/languages/master`;
export const CREATE_LANGUAGE = `${platformManagement}/languages/master/create`;
export const UPDATE_LANGUAGE = (languageId) => `${platformManagement}/languages/master/${languageId}`;
export const UPDATE_LANGUAGE_STATUS = (languageId) => `${platformManagement}/languages/master/${languageId}/status`;

//---- Business unit
export const BUSINESS_UNITS = `${platformManagement}/business_units`;
export const UPDATE_BUSINESS = (BusinessId) => `${platformManagement}/business_units/${BusinessId}`;

//---- Custom elemets
export const CUSTOM_ELEMENTS = `${masterPlatformManagement}/elements`;
export const UPDATE_ELEMENTS = (elemId) => `${masterPlatformManagement}/elements/${elemId}`;
export const UNMAPPED_CUSTOM_ELEMENTS = (elemId) => `${platformManagement}/master/objects/${elemId}/elements/unmapped`;
//------transaction  data sets
export const DATASETS = `${masterPlatformManagement}/datasets`;
export const SELECTED_DATASET = (dataId) => `${masterPlatformManagement}/datasets/${dataId}`;
export const SELECTED_DATASET_TABLE_CONFIG = (dataId) => `${masterPlatformManagement}/datasets/${dataId}/table-config/structure`;
export const SELECTED_DATASET_DISPLAY_CONFIG = (dataId) => `${masterPlatformManagement}/datasets/${dataId}/table-config/data`;



