import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Checkbox, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withRouter } from "react-router-dom";
import { setLoading } from "../../ducks/loading";
import { componentsList } from "./TransactionStepper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: "35px",
        marginRight: "auto",
        marginLeft: "auto",
        // backgroundColor: '#FFF',
        borderRadius: 8,
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    rootAuto: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
            padding: '6.5px 4px'
        },
        '& .MuiFormControl-root label': {
            top: '2px'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    },
    autocompleteRoot1: {
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
}));



const TableInfo = ({ details, handleChangeAutocomplete, handleChange, industries, objectElementsList }) => {
    const classes = useStyles();



    return (
        <>
            <div className={classes.root}>
                <Grid container justify="center" spacing={2} className={classes.paddingBasicDetails}>
                    <Grid item xs={12} md={10} >
                        <Autocomplete
                            id="industry"
                            autoComplete
                            options={industries}
                            getOptionLabel={(option) => option.name}
                            value={details?.industry && details.industry}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Industry"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'industry')}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <Autocomplete
                            id="component"
                            autoComplete
                            options={componentsList}
                            getOptionLabel={(option) => option.name}
                            value={details?.component && details.component}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Component"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'component')}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}></Grid>
                </Grid>
            </div >
        </>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(TableInfo));
