import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Dialog, DialogActions, Grid, Tab, Tabs, DialogContent, DialogTitle, TablePagination, Divider, Radio, IconButton, makeStyles, TextField, Switch } from '@material-ui/core';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading } from '../ducks/loading';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import Axios from 'axios';
import { UPDATE_KPI, CREATE_KPI } from '../config';
import MaterialTable, { MTableToolbar } from 'material-table';
import classNames from 'classnames';
import { MeasurementTypes, durationUnits, ScoringUnitTypes } from './MasterKpi';
import CurrencyCodes from './CurrencyCodes.json'
import { Checkbox } from '@material-ui/core';
import TeamMatchScoringRule from './scoringRules/TeamMatchScoringRule';
import TeamBonusScoringRule from './scoringRules/TeamBonusScoringRule'
import PlayerBonusAndMatchRule, { colorOptions } from './scoringRules/PlayerBonusAndMatchRule';
import TeamRaceScoringRule from './scoringRules/TeamRaceScoringRule';


const useStyles = makeStyles(theme => ({
    root: {
        padding: "25px",
        width: "100%",
    },
    dialogPaper: {
        minHeight: 360,
        minWidth: '92vw',
        height: 'calc(100% - 64px)',
        borderRadius: 15,
        margin: 0
    },
    dialogTitle: {
        // paddingBottom: 0,
        backgroundColor: "#f5f6f7",
        fontSize: 18,
        color: '#262626'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12
    },
    dialogContent: {
        // padding: 20,
        // maxHeight: 300,
        backgroundColor: '#F0F2F5'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 7px !important',
        // width: 280
    },
    tabsRoot: {
        // padding: '14px 0px',
        minWidth: 280,
        // maxWidth: 400,
        // margin: 'auto'
        // borderBottom: '1px solid #0000003a',
        paddingBottom: 0,
        margin: "15px 0px 30px 0px"
    },
    tabsFixed: {
        maxWidth: 760
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        minWidth: 140,
        color: '#838383',
    },
    tabWrapper: {
        flexDirection: 'row',
        // justifyContent: 'flex-start',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 10px 0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px',
        border: 'none',
    },
    tabIndicator: {
        height: '3px',
    },
    footerButton: {
        margin: "5px 18px",
        // width: 135,
        // height: 47,
        '&:last-child': {
            marginRight: 0
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        margin: 'auto',
        width: 50,
        height: 50,
        '& circle': {
            fill: theme.palette.primary.main
        }
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        // width: 280
    },
    tableStyles: {
        border: '1px solid #00000061',
        borderCollapse: 'collapse',
        '& .tr,td': {
            padding: '3px 20px',
            border: '1px solid #00000061',
            borderCollapse: 'collapse'
        }
    },
    tableCellBgColor: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.light
    },
    checkBoxStyle: {
        padding: 0,
        display: 'flex'
    },
    tableNoteStyles: {
        '& .MuiToolbar-root': {
            paddingLeft: 0,
            '& .note': {
                fontSize: 13,
                padding: 0,
                margin: 0
            }
        }

    },
    toggleRoot: {
        display: 'flex',
        alignItems: 'center',
        height: 38,
        maxWidth: 272,
        border: '1px solid #BCBCBD',
        fontSize: 14,
        borderRadius: 4,
        '& p': {
            margin: 0,
            padding: '0 15px 0 5px',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '& > span': {
                paddingRight: 5
            }
        },
        '& hr': {
            // margin: '0 5px',
            backgroundColor: '#BCBCBD'
        },
        '& .selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
            pointerEvents: 'none',
            '& span': {
                color: '#FFF'
            }
        },
        selected: {
            color: theme.palette.primary.main,
            // pointerEvents: 'none',
            // '& span': {
            //     color: '#FFF'
            // }
        },
        dialogPaperScrollPaper: {
            display: 'flex',
            maxHeight: 'calc(100% - 20px)',
            flexDirection: 'column'
        },
        addtionalScoreInputs: {
            padding: 0
        },
        kpiHeadings: {
            fontSize: 18,
            padding: 0,
            textDecoration: 'underline'
        },
        tableNoteStyles: {
            '& .MuiToolbar-root': {
                paddingLeft: 0,
                '& .note': {
                    fontSize: 13,
                    padding: 0,
                    margin: 0
                }
            }

        },
    },
    customToolbarRoot: {
        position: 'absolute',
        top: 0,
        // top: 32,
        // right: 170,
        height: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 80px)'
    },
    basedOnView: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    }, typeHeading: {
        fontSize: 12,
        color: '#747576',
        margin: 0,
        padding: 0
    },
    checkBoxRoot: {
        margin: "-5px 15px 0px 0px",
        padding: 0,
        cursor: 'pointer',
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
    multilineInput: {
        fontSize: 14,
        padding: '15px 14px 12px'
    },
    swtichRoot: {
        marginLeft: 10
    }
}));
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
export const matchScoringConditions = [{ name: "Greater than or equal to opponent’s achievement %", id: "SR1" }, { name: "Greater than or equal to Target", id: "SR2" }, { name: "Less than or equal to target", id: "SR3" }, { name: "Slabwise", id: "SR4" }, { name: "Pro-rata basis", id: "SR5" }, { name: "Slabwise Pro-rata basis", id: "SR6" }];
export const bonusScoringConditions = [{ name: "Slabwise", id: "TBSR1" }, { name: "Pro-rata basis", id: "TBSR2" }, { name: "Base point against target", id: "TBSR3" }, { name: "Slabwise Pro-rata basis", id: "TBSR4" }];

export const getRuleName = (unit = '', value) => {
    let data = ScoringUnitTypes.find(ele => ele.id === unit);
    if (data) {
        if (value <= 1) return data?.name;
        else return `${data?.name}s`;
    }
    else return ''
}

export const scoreAllocatesSr1 = [{ name: 'Team meeting scoring condition', id: 'WINNER' }, { name: 'Any team falling in the slab', id: 'BOTH' }];
export const scoreAllocatesSr2Sr3 = [{ name: 'Team/s meeting the scoring condition', id: 'WINNER' }, { name: 'Any team falling in the slab', id: 'BOTH' }];
export const slabTypes = [{ name: 'Between', id: 'BETWEEN' }, { name: 'Above', id: 'ABOVE' }]

const KpiScoringRulesDialog = ({ open = false, handleClose, setLoading, kpiData, scoringUnit, editType = null, seasonDetails, readOnly = false, functions, industries, ...other }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [teamMatchData, setTeamMatchData] = useState({ basedOn: 'ACHIEVEMENT', additionalScores: [], condition: {}, slabs: [], penaltySlabs: [] });
    const [teamRaceData, setTeamRaceData] = useState({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {}, penaltySlabs: [], indicator_slabs: [] });
    const [teamBonusData, setTeamBonusData] = useState({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {}, penaltySlabs: [], indicator_slabs: [] })
    const [playerMatchData, setPlayerMatchData] = useState({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {}, indicator_slabs: [] })
    const [playerBonusData, setPlayerBonusData] = useState({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {}, indicator_slabs: [] })
    const [details, setDetails] = useState({ tags: [] });
    const [tabValue, setTabValue] = useState(0);
    const [viewAddtionalScores, setViewAddtionalScores] = useState('NO');
    const rulesTabs = [{ label: `Team Core scoring rule`, name: 'TEAM_MATCH', id: 0 }, { label: `Team Bonus scoring rule`, name: 'TEAM_BONUS', id: 1 }, { label: `Player Core scoring rule`, name: 'PLAYER_MATCH', id: 2 }, { label: `Player Bouns scoring rule`, name: 'PLAYER_BONUS', id: 3 }]



    useEffect(() => {
        if (kpiData) {
            let obj = {
                name: kpiData.name,
                display_name: kpiData?.display_name,
                industry_id: industries?.find(el => el._id === kpiData?.industry_id),
                function_id: functions?.find(el => el._id === kpiData?.function_id),
                unit_of_measurement: kpiData.unit_of_measurement,
                unit: kpiData.unit,
                performance_measure_by: kpiData.performance_measure_by || 0,
                tags: kpiData.tags?.map(ele => { return ele.id }),
                description: kpiData.description,
                shortCode: kpiData?.short_code,
                required_run_rate: kpiData?.required_run_rate || false,
                achievement_plan_enabled: kpiData?.achievement_plan_enabled || false,
                indicators_enabled: kpiData?.indicators_enabled || false
            }
            if (kpiData.tags?.some(ele => ele.id === 'TEAM_MATCH') && kpiData.team_match_rule) {
                let teamMatchObj = { basedOn: kpiData.team_match_rule.based_on };
                teamMatchObj.slabs = kpiData.team_match_rule?.slabs?.map(ele => {
                    ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                    return ele
                });
                teamMatchObj.points = kpiData.team_match_rule.base_points;
                teamMatchObj.minimumScore = kpiData.team_match_rule.min_eligibility;
                teamMatchObj.multiplicationFactor = kpiData.team_match_rule.multiplication_factor;
                teamMatchObj.condition = bonusScoringConditions.find(mc => mc.id === kpiData.team_match_rule.condition);
                teamMatchObj.penaltySlabs = kpiData.team_match_rule?.penalty_slabs?.map(ele => {
                    ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                    return ele
                });
                teamMatchObj.indicator_slabs = kpiData.team_match_rule?.indicator_slabs?.map(ele => {
                    ele.status_code = colorOptions.find(cop => cop.id === ele.status_code)
                    ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                    return ele
                });
                setTeamRaceData(teamMatchObj)
                if (teamMatchObj?.additionalScores?.length > 0) setViewAddtionalScores('YES')
            }
            if (kpiData.tags?.some(ele => ele.id === 'TEAM_BONUS') && kpiData.team_bonus_rule) {
                let bonusMatchObj = {
                    basedOn: kpiData.team_bonus_rule.based_on,
                    points: kpiData.team_bonus_rule.base_points,
                    minimumScore: kpiData.team_bonus_rule.min_eligibility,
                    multiplicationFactor: kpiData.team_bonus_rule.multiplication_factor,
                    condition: bonusScoringConditions.find(bc => bc.id === kpiData.team_bonus_rule.condition)
                }
                bonusMatchObj.slabs = kpiData.team_bonus_rule?.slabs?.map(ele => {
                    ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                    return ele;
                });
                bonusMatchObj.penaltySlabs = kpiData.team_bonus_rule?.penalty_slabs?.map(ele => {
                    ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                    return ele
                });

                setTeamBonusData(bonusMatchObj)
            }
            if (kpiData.tags?.some(ele => ele.id === 'PLAYER_MATCH') && kpiData.player_match_rule) {
                let bonusMatchObj = {
                    condition: bonusScoringConditions.find(bc => bc.id === kpiData.player_match_rule.condition),
                    basedOn: kpiData.player_match_rule.based_on,
                    default_role: kpiData.player_match_rule?.default_role,
                    rules: (kpiData.player_match_rule.condition !== 'TBSR1' && kpiData.player_match_rule.condition !== 'TBSR4') ? kpiData.player_match_rule.rules.map(rule => ({
                        ...rule, indicator_slabs: obj.indicators_enabled ? rule?.indicator_slabs.map(slab => ({ ...slab, status_code: colorOptions.find(cop => cop.id === slab.status_code), type: slabTypes.find(s => s.id === slab.type) })) : []
                    })) :
                        kpiData.player_match_rule.rules?.map(slabRule => ({
                            ...slabRule, minEligibility: obj.min_eligibility, slabs: slabRule.slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })), penaltySlabs: slabRule.penalty_slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })),
                            indicator_slabs: obj.indicators_enabled ? slabRule?.indicator_slabs.map(slab => ({ ...slab, status_code: colorOptions.find(cop => cop.id === slab.status_code), type: slabTypes.find(s => s.id === slab.type) })) : []
                        }))
                }
                setPlayerMatchData(bonusMatchObj);
            }
            if (kpiData.tags?.some(ele => ele.id === 'PLAYER_BONUS') && kpiData.player_bonus_rule) {
                let bonusMatchObj = {
                    condition: bonusScoringConditions.find(bc => bc.id === kpiData.player_bonus_rule.condition),
                    basedOn: kpiData.player_bonus_rule.based_on,
                    default_role: kpiData.player_bonus_rule?.default_role,
                    rules: (kpiData.player_bonus_rule.condition !== 'TBSR1' && kpiData.player_bonus_rule.condition !== 'TBSR4') ? kpiData.player_bonus_rule.rules :
                        kpiData.player_bonus_rule.rules?.map(obj => ({ ...obj, minEligibility: obj.min_eligibility, slabs: obj.slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })), penaltySlabs: obj.penalty_slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })) }))
                }
                setPlayerBonusData(bonusMatchObj);
            }
            setDetails(obj);
        }

    }, [kpiData?._id]);

    const handleAutoCompleteChangeInput = (value, field, tab) => {
        setDetails(prevState => {
            return { ...prevState, [field]: value }
        })
    }

    const handleChangeInput = (event, field, tab) => {
        const value = event.target.value;
        if (tab === 'TEAM_MATCH') {
            setTeamMatchData(prevState => {
                return { ...prevState, [field]: value }
            })
        }
        else if (tab === 'TEAM_SPRINT') {
            setTeamRaceData(prevState => {
                return { ...prevState, [field]: value }
            })
        }
        else if (tab === 'TEAM_BONUS') {
            setTeamBonusData(prevState => {
                return { ...prevState, [field]: value }
            })
        }
        else if (tab === 'PLAYER_MATCH') {
            setPlayerMatchData(prevState => {
                return { ...prevState, [field]: value }
            })
        }
        else if (tab === 'PLAYER_BONUS') {
            setPlayerBonusData(prevState => {
                return { ...prevState, [field]: value }
            })
        }
        else if (field === 'name') {
            setDetails(prevState => ({ ...prevState, [field]: value, display_name: value }))
        }
        else setDetails(prevState => ({ ...prevState, [field]: value }));
    };

    const handleChangeAutocomplete = (data, field, tab) => {
        if (tab === 'TEAM_MATCH') {
            if (field === 'condition') {
                setTeamMatchData(prevState => {
                    return { ...prevState, [field]: data, minimumScore: null }
                })
                if (data?.id === 'SR4') {
                    handleViewAddtionalScore('NO')
                    if (data?.id === 'SR4') {
                        handleViewAddtionalScore('NO');
                        setTeamMatchData(prevState => {
                            return { ...prevState, slabs: [] }
                        })
                    }
                }
            }
            else if (field === 'additionalScore') {
                handleViewAddtionalScore(data ? data.value : '')
            }
            else {
                setTeamMatchData(prevState => {
                    return { ...prevState, [field]: data }
                })
            }
        }
        else if (tab === 'TEAM_SPRINT') {
            if (field === 'condition') {
                setTeamRaceData(prevState => {
                    return { ...prevState, [field]: data, slabs: [], basedOn: 'ACHIEVEMENT', points: null, minimumScore: null, multiplicationFactor: null }
                })
            }
            else if ('additionalScore') {
                handleViewAddtionalScore(data.value)
            }
            else {
                setTeamRaceData(prevState => {
                    return { ...prevState, [field]: data }
                })
            }
        }
        else if (tab === 'TEAM_BONUS') {
            if (field === 'condition') {
                setTeamBonusData(prevState => {
                    return { ...prevState, [field]: data, slabs: [], basedOn: 'ACHIEVEMENT', points: null, minimumScore: null, multiplicationFactor: null }
                })
            }
            else if ('additionalScore') {
                handleViewAddtionalScore(data.value)
            }
            else {
                setTeamBonusData(prevState => {
                    return { ...prevState, [field]: data }
                })
            }
        }
        else if (tab === 'PLAYER_MATCH') {
            if (field === 'condition') {
                setPlayerMatchData(prevState => {
                    return { ...prevState, [field]: data, rules: prevState.rules?.map(obj => ({ ...obj, min_eligibility: 0, multiplication_factor: 0, base_points: 0, slabs: [] })) }
                })
            }
            else {
                setPlayerMatchData(prevState => {
                    return { ...prevState, [field]: data }
                })
            }
        }
        else if (tab === 'PLAYER_BONUS') {
            if (field === 'condition') {
                setPlayerBonusData(prevState => {
                    return { ...prevState, [field]: data, rules: prevState.rules?.map(obj => ({ ...obj, min_eligibility: 0, multiplication_factor: 0, base_points: 0 })) }
                })
            }
            else {
                setPlayerBonusData(prevState => {
                    return { ...prevState, [field]: data }
                })
            }
        }
        else if (tab === 'OUTER' && field === 'unit_of_measurement') {
            setDetails(prevState => ({ ...prevState, unit_of_measurement: data, unit: {} }))
        }
        else if (tab === 'OUTER' && field === 'industry_id') {
            setDetails(prevState => ({ ...prevState, industry_id: data, unit: {} }))
        } else if (tab === 'OUTER' && field === 'function_id') {
            setDetails(prevState => ({ ...prevState, function_id: data, unit: {} }))
        }
        else setDetails(prevState => ({ ...prevState, [field]: data }));
    }

    const handleCheck = (value) => {
        let staticRules = ['TEAM_MATCH', 'TEAM_BONUS', 'PLAYER_MATCH', 'PLAYER_BONUS'];
        let data = details.tags ? [...details.tags] : [];
        if (data.includes(value.name)) {
            if (value.name === 'TEAM_MATCH') setTeamMatchData({ basedOn: 'ACHIEVEMENT', additionalScores: [], condition: {} })
            if (value.name === 'TEAM_MATCH') setTeamRaceData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} })
            else if (value.name === 'TEAM_BONUS') setTeamBonusData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} })
            else if (value.name === 'PLAYER_MATCH') setPlayerMatchData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} })
            else if (value.name === 'PLAYER_BONUS') setPlayerBonusData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} })
            data = data.filter(ele => ele !== value.name);
        }
        else {
            setTabValue(0)
            data.push(value.name);
            data = staticRules.filter(ele => data.includes(ele))
        }
        setDetails(prevState => ({ ...prevState, tags: data }));
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleClearData = (value = false) => {
        handleClose(value)
        setTabValue(0)
        setDetails({ tags: [] })
        setViewAddtionalScores('NO')
        setTeamBonusData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} });
        setPlayerMatchData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} });
        setPlayerBonusData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} });
        setTeamRaceData({ basedOn: 'ACHIEVEMENT', slabs: [], condition: {} });
        setTeamMatchData({ basedOn: 'ACHIEVEMENT', additionalScores: [], condition: {}, slabs: [] });
    }

    const handleTeamToggle = (value) => {
        setTeamMatchData(prevState => {
            return { ...prevState, basedOn: value }
        })
    }

    const handleBonusToggle = (value) => {
        setTeamBonusData(prevState => {
            return { ...prevState, basedOn: value }
        })
    }
    const handleRaceToggle = (value) => {
        setTeamRaceData(prevState => {
            return { ...prevState, basedOn: value }
        })
    }

    const handlePlayerToggle = (value, tab) => {
        if (tab === 'PLAYER_MATCH') {
            setPlayerMatchData(prevState => {
                return { ...prevState, basedOn: value }
            })
        }
        else if (tab === 'PLAYER_BONUS') {
            setPlayerBonusData(prevState => {
                return { ...prevState, basedOn: value }
            })
        }
    }

    const handleSubmit = () => {
        let obj = { ...details };
        if (obj.tags?.length && obj.tags.includes('TEAM_MATCH')) {
            const finalTeamMatchData = { ...teamMatchData, slabs: teamMatchData.slabs?.filter(obj => obj.min !== null && obj.min !== 0), penaltySlabs: teamMatchData.penaltySlabs?.filter(obj => obj.min !== null && obj.min !== 0) };
            const finalTeamRaceData = { ...teamRaceData, slabs: teamRaceData.slabs?.filter(obj => obj.min !== null && obj.min !== 0), penaltySlabs: teamRaceData.penaltySlabs?.filter(obj => obj.min !== null && obj.min !== 0) };
            obj['team_match_rule'] = { ...finalTeamRaceData };
        }
        if (obj.tags?.length && obj.tags.includes('TEAM_BONUS')) {
            const finalTeamBonusData = { ...teamBonusData, slabs: teamBonusData.slabs?.filter(obj => (obj.min !== null && obj.min !== 0)), penaltySlabs: teamBonusData.penaltySlabs?.filter(obj => obj.min !== null && obj.min !== 0) };
            obj['team_bonus_rule'] = { ...finalTeamBonusData };
        }
        if (obj.tags?.length && obj.tags.includes('PLAYER_MATCH')) {
            const finalPlayerMatchData = { ...playerMatchData, rules: playerMatchData.rules?.map(obj => ({ ...obj, slabs: obj.slabs?.filter(obj => (obj.min !== null && obj.min !== 0)), penaltySlabs: obj.penaltySlabs?.filter(obj => (obj.min !== null && obj.min !== 0)) })) || [] };
            obj['player_match_rule'] = { ...finalPlayerMatchData };
        }
        if (obj.tags?.length && obj.tags.includes('PLAYER_BONUS')) {
            const finalPlayerBonusData = { ...playerBonusData, rules: playerBonusData.rules?.map(obj => ({ ...obj, slabs: obj.slabs?.filter(obj => (obj.min !== null && obj.min !== 0)), penaltySlabs: obj.penaltySlabs?.filter(obj => (obj.min !== null && obj.min !== 0)) })) || [] };
            obj['player_bonus_rule'] = { ...finalPlayerBonusData };
        }
        if (!obj.name?.trim() || !obj.shortCode?.trim() || !obj.unit_of_measurement || !obj?.industry_id || !obj?.function_id || !((obj?.performance_measure_by < 0 || obj.unit_of_measurement?.id === 'DURATION') ? obj.unit?.id : true)) {
            enqueueSnackbar(`Please enter required KPI details.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if ((obj.team_match_rule && (!obj.team_match_rule?.condition?.id
            || (obj.team_match_rule?.condition?.id === 'TBSR2' && ((obj.team_match_rule?.minimumScore === undefined || obj.team_match_rule?.minimumScore < 0) || !obj.team_match_rule?.multiplicationFactor))
            || (obj.team_match_rule?.condition?.id === 'TBSR3' && (!obj.team_match_rule?.points)))
        )) {
            enqueueSnackbar(`Please enter required fields for scoring rules or deselect the respective KPI type values ticked.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if ((obj.team_match_rule && (!obj.team_match_rule?.condition?.id)
            || (obj.team_match_rule?.condition?.id === 'TBSR1' && obj.team_match_rule?.slabs?.length === 0) || (obj.team_match_rule?.condition?.id === 'TBSR4' && (obj.team_match_rule?.slabs?.length === 0 || (obj.team_match_rule?.minimumScore > 0 ? (obj.team_match_rule?.penaltySlabs?.length === 0) : false))))) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('TEAM_MATCH'))
            enqueueSnackbar(`Please add atleast one slab in team match scoring rule.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (obj.team_bonus_rule && (!obj.team_bonus_rule?.condition?.id
            || (obj.team_bonus_rule?.condition?.id === 'TBSR2' && ((obj.team_bonus_rule?.minimumScore === undefined || obj.team_bonus_rule?.minimumScore < 0) || !obj.team_bonus_rule?.multiplicationFactor))
            || (obj.team_bonus_rule?.condition?.id === 'TBSR3' && (!obj.team_bonus_rule?.points)))

        ) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('TEAM_BONUS'))
            enqueueSnackbar(`Please enter required fields for scoring rules or deselect the respective KPI type values ticked.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (obj.team_bonus_rule && (!obj.team_bonus_rule?.condition?.id)
            || (obj.team_bonus_rule?.condition?.id === 'TBSR1' && obj.team_bonus_rule?.slabs?.length === 0) || (obj.team_bonus_rule?.condition?.id === 'TBSR4' && (obj.team_bonus_rule?.slabs?.length === 0 || (obj.team_bonus_rule?.minimumScore > 0 ? (obj.team_bonus_rule?.penaltySlabs?.length === 0) : false)))) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('TEAM_BONUS'))
            enqueueSnackbar(`Please add atleast one slab in team bonus scoring rule.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (obj.player_match_rule && (!obj.player_match_rule?.condition?.id
            || (obj.player_match_rule?.rules?.length === 0))
        ) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('PLAYER_MATCH'));
            enqueueSnackbar(`Please enter required fields for scoring rules or deselect the respective KPI type values ticked.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (obj.player_match_rule && ((obj.player_match_rule?.condition?.id === 'TBSR1' && obj.player_match_rule.rules?.some(obj => obj?.slabs?.length === 0))
            || (obj.player_match_rule?.condition?.id === 'TBSR4' && (obj.player_match_rule.rules?.some(obj => obj?.slabs?.length === 0) || obj.player_match_rule.rules?.some(obj => (obj.minEligibility > 0 ? (obj?.penaltySlabs?.length === 0) : false))))
            || (obj.player_match_rule?.condition?.id === 'TBSR2' && obj.player_match_rule.rules?.some(obj => (obj?.min_eligibility < 0 || !obj?.multiplication_factor)))
            || (obj.player_match_rule?.condition?.id === 'TBSR3' && obj.player_match_rule.rules?.some(obj => !obj?.base_points)))) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('PLAYER_MATCH'));
            enqueueSnackbar((obj.player_match_rule?.condition?.id === 'TBSR1' || obj.player_match_rule?.condition?.id === 'TBSR4') && "Please enter data for all mandatory fields", {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (obj.player_bonus_rule && (!obj.player_bonus_rule?.condition?.id
            || (obj.player_bonus_rule?.rules?.length === 0))
        ) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('PLAYER_BONUS'));
            enqueueSnackbar(`Please enter required fields for scoring rules or deselect the respective KPI type values ticked.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (obj.player_bonus_rule && ((obj.player_bonus_rule?.condition?.id === 'TBSR1' && obj.player_bonus_rule.rules?.some(obj => obj?.slabs?.length === 0))
            || (obj.player_bonus_rule?.condition?.id === 'TBSR4' && (obj.player_bonus_rule.rules?.some(obj => obj?.slabs?.length === 0) || obj.player_bonus_rule.rules?.some(obj => (obj.minEligibility > 0 ? (obj?.penaltySlabs?.length === 0) : false))))
            || (obj.player_bonus_rule?.condition?.id === 'TBSR2' && obj.player_bonus_rule.rules?.some(obj => (obj?.min_eligibility < 0 || !obj?.multiplication_factor)))
            || (obj.player_bonus_rule?.condition?.id === 'TBSR3' && obj.player_bonus_rule.rules?.some(obj => !obj?.base_points)))) {
            if (obj.tags.length > 1) setTabValue(obj.tags.indexOf('PLAYER_BONUS'));
            enqueueSnackbar((obj.player_bonus_rule?.condition?.id === 'TBSR1' || obj.player_bonus_rule?.condition?.id === 'TBSR4') ? "Please enter data for all mandatory fields" : '', {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else {
            let requestObj = {
                name: obj.name.trim(),
                display_name: obj?.display_name.trim(),
                industry_id: obj?.industry_id?.id,
                function_id: obj?.function_id?.id,
                unit_of_measurement: obj.unit_of_measurement?.id,
                performance_measure_by: obj.performance_measure_by,
                unit: obj.unit?.id,
                tags: obj.tags || [],
                short_code: obj.shortCode,
                description: obj.description,
                required_run_rate: (teamMatchData.basedOn !== 'ACHIEVEMENT' || teamBonusData.basedOn !== 'ACHIEVEMENT' || teamRaceData.basedOn !== 'ACHIEVEMENT' || playerBonusData.basedOn !== 'ACHIEVEMENT' || playerMatchData.basedOn !== 'ACHIEVEMENT') ? false : obj.required_run_rate,
                indicators_enabled: obj.indicators_enabled,
            }
            requestObj.achievement_plan_enabled = details.achievement_plan_enabled
            if (requestObj.tags.includes('TEAM_MATCH')) {
                if (['SR4', 'SR5', 'SR6'].indexOf(obj.team_match_rule.condition?.id) === -1) {
                    requestObj.team_match_rule = {
                        condition: obj.team_match_rule.condition?.id,
                        base_points: obj.team_match_rule?.points,
                        score: parseInt(obj.team_match_rule.score),
                        based_on: obj.team_match_rule.basedOn ? obj.team_match_rule.basedOn : 'ACHIEVEMENT',
                        additional_score_config: obj.team_match_rule?.additionalScores?.map(ele => {
                            ele.type = ele.type?.id
                            ele.allocate_to = ele.allocate_to?.id;
                            return ele;
                        }),
                        slabs: obj.team_match_rule?.slabs?.map(ele => {
                            ele.type = ele.type?.id;
                            return ele;
                        }),
                        penalty_slabs: obj.team_match_rule.penaltySlabs?.map(ele => {
                            ele.type = ele.type?.id;
                            return ele;
                        }),
                        indicator_slabs: obj.indicators_enabled ? obj.team_match_rule?.indicator_slabs?.map(ele => {
                            ele.type = ele.type?.id;
                            ele.title = ele.status_code?.name
                            ele.status_code = ele.status_code?.id;
                            return ele;
                        }) : [],
                        min_eligibility: obj.team_match_rule.minimumScore,
                        multiplication_factor: obj.team_match_rule.multiplicationFactor
                    }
                }
                else {
                    requestObj.team_match_rule = {
                        condition: obj.team_match_rule.condition?.id,
                        base_points: obj.team_match_rule?.points,
                        based_on: obj.team_match_rule.basedOn ? obj.team_match_rule.basedOn : 'ACHIEVEMENT',
                        slabs: obj.team_match_rule?.slabs?.map(ele => {
                            ele.type = ele.type?.id;
                            return ele;
                        }),
                        penalty_slabs: obj.team_match_rule.penaltySlabs?.map(ele => {
                            ele.type = ele.type?.id;
                            return ele;
                        }),
                        indicator_slabs: obj.indicators_enabled ? obj.team_match_rule?.indicator_slabs?.map(ele => {
                            ele.type = ele.type?.id;
                            ele.title = ele.status_code?.name
                            ele.status_code = ele.status_code?.id;
                            return ele;
                        }) : [],
                        min_eligibility: obj.team_match_rule.minimumScore,
                        multiplication_factor: obj.team_match_rule.multiplicationFactor
                    }
                }
            }
            if (requestObj.tags.includes('TEAM_BONUS')) {
                requestObj.team_bonus_rule = {
                    condition: obj.team_bonus_rule.condition?.id,
                    base_points: obj.team_bonus_rule.points,
                    based_on: obj.team_bonus_rule.basedOn ? obj.team_bonus_rule.basedOn : 'ACHIEVEMENT',
                    slabs: obj.team_bonus_rule.slabs?.map(ele => {
                        ele.type = ele.type?.id;
                        return ele;
                    }),
                    penalty_slabs: obj.team_bonus_rule.penaltySlabs?.map(ele => {
                        ele.type = ele.type?.id;
                        return ele;
                    }),
                    min_eligibility: obj.team_bonus_rule.minimumScore,
                    multiplication_factor: obj.team_bonus_rule.multiplicationFactor
                }
            }
            if (requestObj.tags.includes('PLAYER_MATCH')) {
                requestObj.player_match_rule = {
                    condition: obj.player_match_rule.condition?.id,
                    based_on: obj.player_match_rule.basedOn ? obj.player_match_rule.basedOn : 'ACHIEVEMENT',
                    default_role: obj.player_match_rule?.default_role,
                    rules: !['TBSR1', 'TBSR4'].includes(obj.player_match_rule.condition?.id) ? obj.player_match_rule.rules.map(rule => ({
                        ...rule, indicator_slabs: obj.indicators_enabled ? rule?.indicator_slabs && rule?.indicator_slabs.map(slab => ({ ...slab, type: slab.type?.id, status_code: slab.status_code?.id, title: slab.status_code?.name })) : []
                    })) :
                        obj.player_match_rule.rules?.map(ruleObj => ({
                            ...ruleObj, min_eligibility: ruleObj.minEligibility, slabs: ruleObj.slabs?.map(slab => ({ ...slab, type: slab.type?.id })), penalty_slabs: ruleObj.penaltySlabs?.map(slab => ({ ...slab, type: slab.type?.id })),
                            indicator_slabs: obj.indicators_enabled ? ruleObj.indicator_slabs?.map(slab => ({ ...slab, type: slab.type?.id, status_code: slab.status_code?.id, title: slab.status_code?.name })) : []
                        })) || [],


                }
            }
            if (requestObj.tags.includes('PLAYER_BONUS')) {
                requestObj.player_bonus_rule = {
                    condition: obj.player_bonus_rule.condition?.id,
                    based_on: obj.player_bonus_rule.basedOn ? obj.player_bonus_rule.basedOn : 'ACHIEVEMENT',
                    default_role: obj.player_bonus_rule?.default_role,
                    rules: (obj.player_bonus_rule.condition?.id !== 'TBSR1' && obj.player_bonus_rule.condition?.id !== 'TBSR4') ? obj.player_bonus_rule.rules :
                        obj.player_bonus_rule.rules?.map(obj => ({ ...obj, min_eligibility: obj.minEligibility, slabs: obj.slabs?.map(slab => ({ ...slab, type: slab.type?.id })), penalty_slabs: obj.penaltySlabs?.map(slab => ({ ...slab, type: slab.type?.id })) })) || []
                }
            }
            if (editType && !requestObj.tags.includes('TEAM_MATCH')) { requestObj.team_match_rule = {} }
            if (editType && !requestObj.tags.includes('TEAM_BONUS')) { requestObj.team_bonus_rule = {} }
            if (editType && !requestObj.tags.includes('PLAYER_MATCH')) { requestObj.player_match_rule = {} }
            if (editType && !requestObj.tags.includes('PLAYER_BONUS')) { requestObj.player_bonus_rule = {} }

            if (editType && editType === 'ALL') {
                Axios.put(UPDATE_KPI(kpiData._id), requestObj)
                    .then(response => {
                        handleClearData(true)
                        enqueueSnackbar("KPI and rules updated successfully", {
                            variant: "success",
                            preventDuplicate: true
                        });
                    })
                    .catch(error => {
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to add KPI.', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            }
            else {
                Axios.post(CREATE_KPI(), requestObj)
                    .then(response => {
                        handleClearData(true);
                        enqueueSnackbar(requestObj.tags.length > 0 ? "KPI and rules are configured successfully" : "KPI created successfully", {
                            variant: "success",
                            preventDuplicate: true
                        });
                    })
                    .catch(error => {
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to add KPI.', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            }
        }

    }

    const getUnit = () => {
        if (details.unit_of_measurement && details.unit_of_measurement?.id === 'CURRENCY') {
            return ` ${details.unit.id}`;
        }
        else if (details.unit_of_measurement && details.unit_of_measurement?.id === 'DURATION') {
            return ` ${details.unit.name}`;
        }
        else return ''
    }

    const handleViewAddtionalScore = (value) => {
        setViewAddtionalScores(value)
        if (value !== 'YES') {
            setTeamMatchData(prevState => {
                return { ...prevState, additionalScores: [], basedOn: 'ACHIEVEMENT' }
            });
        }
    }

    const handelChangeTeamMatchData = (field, data) => {
        setTeamMatchData(prevState => {
            return { ...prevState, [field]: data }
        });
    }
    const handelChangeTeamRaceData = (field, data) => {
        setTeamRaceData(prevState => {
            return { ...prevState, [field]: data }
        });
    }

    const handelChangeTeamBonusData = (field, data) => {
        setTeamBonusData(prevState => {
            return { ...prevState, [field]: data }
        });
    }
    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.dialogPaperScrollPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            // maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
            onClose={handleClose}
        >
            <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                KPI configuration
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={() => handleClearData(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent} id="nextListItem">
                <div>

                    <Grid container spacing={3} >
                        <Grid item xs={6} md={3} style={{ marginTop: 3 }}>
                            <TextField fullWidth
                                required
                                variant="outlined"
                                id="kpi-name"
                                value={details.name}
                                label="KPI Name"
                                autoComplete='off'
                                onChange={e => handleChangeInput(e, 'name', 'OUTER')}
                                // style={{ minWidth: 125 }}
                                disabled={(editType && editType !== 'ALL')}
                            />
                        </Grid>
                        <Grid item xs={6} md={3} style={{ marginTop: 3 }}>
                            <TextField fullWidth
                                required
                                variant="outlined"
                                id="kpi-short-code"
                                value={details.shortCode}
                                inputProps={{ maxLength: 3 }}
                                label="Short code"
                                autoComplete='off'
                                onChange={e => handleChangeInput(e, 'shortCode', 'OUTER')}
                                // style={{ minWidth: 125 }}
                                disabled={(editType && editType !== 'ALL')}
                            />
                        </Grid>
                        <Grid item xs={6} md={3} style={{ marginTop: 3 }}>
                            <TextField fullWidth
                                variant="outlined"
                                id="kpi-display-name"
                                defaultValue={details.name}
                                value={details.display_name}
                                InputLabelProps={{ shrink: true }}
                                label="KPI Display Name"
                                autoComplete='off'
                                onChange={e => handleChangeInput(e, 'display_name', 'OUTER')}
                            />
                        </Grid>
                        <Grid item xs={6} md={3} >
                            <Autocomplete
                                id="industries"
                                options={industries}
                                getOptionLabel={(option) => option.name || ''}
                                value={details?.industry_id}
                                disabled={editType}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label="Industry" variant="outlined" required />}
                                onChange={(...args) => { handleChangeAutocomplete(args[1], 'industry_id', 'OUTER') }
                                }
                            />
                        </Grid>
                        <Grid item xs={6} md={3} >
                            <Autocomplete
                                id="functions"
                                options={functions}
                                getOptionLabel={(option) => option.name || ''}
                                value={details?.function_id}
                                disabled={editType}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label="Function" variant="outlined" required />}
                                onChange={(...args) => { handleChangeAutocomplete(args[1], 'function_id', 'OUTER') }
                                }
                            />
                        </Grid>
                        <Grid item xs={6} md={3} >
                            <Autocomplete
                                id="unit-of-Measurement"
                                options={MeasurementTypes}
                                getOptionLabel={(option) => option.name || ''}
                                value={details?.unit_of_measurement}
                                disabled={(editType && editType !== 'ALL')}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label="Measurement" variant="outlined" required />}
                                onChange={(...args) => { handleChangeAutocomplete(args[1], 'unit_of_measurement', 'OUTER') }
                                }
                            />
                        </Grid>
                        {
                            (details.unit_of_measurement && (details.unit_of_measurement.id === 'DURATION')) && //details.unit_of_measurement.id === 'CURRENCY' || 
                            <Grid item xs={6} md={3} >
                                <Autocomplete
                                    id="unit"
                                    disabled={(editType && editType !== 'ALL')}
                                    options={details?.unit_of_measurement.id === 'CURRENCY' ? CurrencyCodes : durationUnits}
                                    getOptionLabel={(option) => option.name ? `${option.name} ${details?.unit_of_measurement.id === 'CURRENCY' ? `- (${option.id})` : ''}` : ''}
                                    value={details?.unit}
                                    // style={{ width: 215 }}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label={details?.unit_of_measurement.id === 'CURRENCY' ? 'Currency code' : 'Unit'} variant="outlined" required />}
                                    onChange={(...args) => handleChangeAutocomplete(args[1], 'unit', 'OUTER')}
                                />
                            </Grid>
                        }
                        <Grid item xs={6} md={3}>
                            <Autocomplete
                                id="performance-is-better"
                                options={[{ id: 0, name: 'When Actual > Target' }, { id: 1, name: 'When Actual < Target' }]}
                                getOptionLabel={(option) => option.name || ''}
                                value={details?.performance_measure_by < 1 ? { id: 0, name: 'When Actual > Target' } : { id: 1, name: 'When Actual < Target' }}
                                disabled={(editType && editType !== 'ALL')}
                                disableClearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label="Performance is better" variant="outlined" required />}
                                onChange={(...args) => { handleAutoCompleteChangeInput(args[1]?.id, 'performance_measure_by', 'OUTER') }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                multiline
                                rowsMax={4}
                                value={details.description}
                                id="outlined-required"
                                label="KPI Description"
                                variant="outlined"
                                fullWidth
                                autoComplete='off'
                                onChange={e => handleChangeInput(e, 'description', 'OUTER')}
                                disabled={(editType && editType !== 'ALL')}
                                InputProps={{ classes: { multiline: classes.multilineInput } }}
                            />
                        </Grid>
                        {details?.performance_measure_by === 0 &&
                            <Grid item xs={6} md={3} >
                                <Autocomplete
                                    id="required_run_rate"
                                    options={[{ id: 0, name: 'Yes', value: true }, { id: 1, name: 'No', value: false }]}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={details?.required_run_rate ? { id: 0, name: 'Yes', value: true } : { id: 1, name: 'No', value: false }}
                                    disabled={(editType && editType !== 'ALL')}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" id="required_run_rate" label="Is Required Run Rate" required />}
                                    onChange={(...args) => handleChangeAutocomplete(args[1]?.value, 'required_run_rate', 'OUTER')}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={12} >
                            <div style={(editType && editType !== 'ALL') ? { alignItems: 'center', pointerEvents: 'none' } : { alignItems: 'center', marginLeft: 4 }}>
                                <table className={classes.tableStyles}>
                                    <tr>
                                        <td className={classes.tableCellBgColor}>Enable scoring rule for</td>
                                        <td className={classes.tableCellBgColor}>Team</td>
                                        <td className={classes.tableCellBgColor}>Player</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableCellBgColor}>Core</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                className={classes.checkBoxStyle}
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={details.tags ? details.tags.includes('TEAM_MATCH') : false}
                                                onClick={() => handleCheck({ name: 'TEAM_MATCH', id: 0 })}
                                                color="primary"
                                            />
                                        </td>
                                        <td> <Checkbox
                                            className={classes.checkBoxStyle}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={details.tags ? details.tags.includes('PLAYER_MATCH') : false}
                                            onClick={() => handleCheck({ name: 'PLAYER_MATCH', id: 2 })}
                                            color="primary"
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableCellBgColor}> Bonus</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                icon={icon}
                                                className={classes.checkBoxStyle}
                                                checkedIcon={checkedIcon}
                                                checked={details?.tags ? details?.tags.includes('TEAM_BONUS') : false}
                                                onClick={() => handleCheck({ name: 'TEAM_BONUS', id: 1 })}
                                                color="primary"
                                            />
                                        </td>
                                        <td>
                                            <Checkbox
                                                icon={icon}
                                                className={classes.checkBoxStyle}
                                                checkedIcon={checkedIcon}
                                                checked={details?.tags ? details?.tags.includes('PLAYER_BONUS') : false}
                                                onClick={() => handleCheck({ name: 'PLAYER_BONUS', id: 3 })}
                                                color="primary"
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={classes.tabsRoot}
                    classes={{ indicator: classes.tabIndicator, fixed: classes.tabsFixed }}
                >
                    {
                        rulesTabs.map(tab => {
                            if (((!editType && details.tags) && details.tags.includes(tab.name)) || ((editType && details.tags) && details.tags.includes(tab.name))) {
                                return (
                                    <Tab className={classes.tabRoot} classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon }} label={tab.label} />
                                )
                            }
                        })
                    }
                </Tabs>
                <Grid container spacing={5}>
                    {
                        (((!editType && details.tags) && (details.tags.includes('TEAM_MATCH') && tabValue === details.tags.indexOf('TEAM_MATCH'))) || ((editType && details.tags) && tabValue === details?.tags.indexOf('TEAM_MATCH'))) &&
                        <>
                            <TeamRaceScoringRule
                                teamRaceData={teamRaceData}
                                scoringUnit={scoringUnit}
                                details={details}
                                handelChangeTeamRcaeData={handelChangeTeamRaceData}
                                handleChangeAutocomplete={handleChangeAutocomplete}
                                handleChangeInput={handleChangeInput}
                                getUnit={getUnit}
                                handleRaceToggle={handleRaceToggle}
                            />
                        </>
                    }
                    {
                        (((!editType && details.tags) && (details.tags.includes('TEAM_BONUS') && tabValue === details.tags.indexOf('TEAM_BONUS'))) || ((editType && details.tags) && tabValue === details.tags.indexOf('TEAM_BONUS'))) &&
                        <>
                            <TeamBonusScoringRule
                                teamBonusData={teamBonusData}
                                scoringUnit={scoringUnit}
                                details={details}
                                handelChangeTeamBonusData={handelChangeTeamBonusData}
                                handleChangeAutocomplete={handleChangeAutocomplete}
                                handleChangeInput={handleChangeInput}
                                getUnit={getUnit}
                                handleBonusToggle={handleBonusToggle}
                            />
                        </>
                    }
                    {
                        (((!editType && details.tags) && (details.tags.includes('PLAYER_MATCH') && tabValue === details.tags.indexOf('PLAYER_MATCH'))) || ((editType && details.tags) && tabValue === details.tags.indexOf('PLAYER_MATCH'))) &&
                        <>
                            <PlayerBonusAndMatchRule
                                data={playerMatchData}
                                tag={'PLAYER_MATCH'}
                                scoringUnit={scoringUnit}
                                details={details}
                                handelChangeData={setPlayerMatchData}
                                handleChangeAutocomplete={handleChangeAutocomplete}
                                handleChangeInput={handleChangeInput}
                                getUnit={getUnit}
                                handlePlayerToggle={handlePlayerToggle}
                            />
                        </>
                    }
                    {
                        (((!editType && details.tags) && (details.tags.includes('PLAYER_BONUS') && tabValue === details.tags.indexOf('PLAYER_BONUS'))) || ((editType && details.tags) && tabValue === details.tags.indexOf('PLAYER_BONUS'))) &&
                        <>
                            <PlayerBonusAndMatchRule
                                data={playerBonusData}
                                tag={'PLAYER_BONUS'}
                                scoringUnit={scoringUnit}
                                details={details}
                                handelChangeData={setPlayerBonusData}
                                handleChangeAutocomplete={handleChangeAutocomplete}
                                handleChangeInput={handleChangeInput}
                                getUnit={getUnit}
                                handlePlayerToggle={handlePlayerToggle}
                                editType={editType}
                            />
                        </>
                    }
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: '10px 25px', backgroundColor: '#F0F2F5' }}>
                <Button onClick={() => handleClearData(false)} className={classes.footerButton} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleSubmit()} className={classes.footerButton} variant="contained" color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
};
const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};
export default withRouter(connect(
    null,
    mapDispatchToProps
)(KpiScoringRulesDialog));
