import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button, Checkbox, IconButton, Link, TableCell, TableRow, TextField, Typography } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import { connect } from "react-redux";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import MaterialTable, { MTableToolbar } from "material-table";
import { accumulators, componentTableDuration, tableRange } from "./TransactionStepper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteIcon from '@material-ui/icons/Delete';
import { Delete, Edit } from "@material-ui/icons";
import ElementOrderingDialog from "./ElementOrderingDialog";
import SettingsTablePreview from "./SettingsTablePreview";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: "35px",
        marginRight: "auto",
        marginLeft: "auto",
        // backgroundColor: '#FFF',
        borderRadius: 8,
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    rootAuto: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
            padding: '6.5px 4px'
        },
        '& .MuiFormControl-root label': {
            top: '2px'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    },
    autocompleteRoot1: {
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
    tableNoteStyles: {
        '& .btn': {
            textTransform: 'capitalize',
            fontSize: 12
        },
        '& .MuiToolbar-root': {
            paddingLeft: 0,
            height: 0,
            marginBottom: 0,
            '& .note': {
                fontSize: 13,
                padding: 0,
                margin: 0
            }
        },
    },
    btn: {
        minWidth: 40,
        maxWidth: 45,
        height: 40,
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        fontSize: 12,
        color: '#262626',
        marginRight: 10,
        padding: 0,
        pointerEvents: 'none'
    },
}));



const TableSettings = ({ details, handleChangeAutocomplete, handleChange, objects, objectElementsList, sortingElements, handleElementSortingCreate, handleElementSortingUpdate, handleElementSortingDelete, onDragEnd }) => {
    const classes = useStyles();
    const myTextIcon = React.useRef(null);
    const [openElementDialog, setOpenElementDialog] = useState(false);
    const [selectedElement, setSelectedElement] = useState();
    const [isOpenPreviewDailog, setIsOpenPreviewDailog] = useState(false);


    const column = [
        {
            title: "No",
            field: "element",
            editable: false,
            cellStyle: { width: 100, maxWidth: 100 },
            headerStyle: { width: 100, maxWidth: 100 },
            width: 70,
            render: (rowData) =>
                rowData && <Button className={classes.btn}>{sortingElements.indexOf(rowData) + 1}</Button>
        },
        {
            title: `Element`,
            field: "element",
            cellStyle: { width: 150, maxWidth: 250 },
            headerStyle: { width: 150, maxWidth: 250 },
            editComponent: props => (
                <Autocomplete
                    id="element"
                    options={objectElementsList}
                    getOptionLabel={(option) => option.name || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 125 }} label='Element' variant="outlined" required />}
                    onChange={(...args) => { props.onChange(args[1]) }}
                />
            ),
            render: (rowData) => rowData.elementName,
        }
    ];


    const EnhancedTableToolbar = () => {
        const classes = useStyles();
        return (
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    disableRipple
                    className="btn"
                    onClick={() => { setOpenElementDialog(true) }}
                >
                    + Add New Element
                </Button>
            </div >
        );
    };


    // --------------element ordering---------
    const handleElementDialog = () => {
        setOpenElementDialog(false);
        setSelectedElement();
    };

    const closePreviewDailog = () => {
        setIsOpenPreviewDailog(false);
    }

    return (
        <>
            <div className={classes.root}>
                <Grid container justify="center" spacing={2} className={classes.paddingBasicDetails}>
                    <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="table-name"
                                    value={details?.name ? details?.name : ''}
                                    label="Name"
                                    required
                                    onChange={e => handleChange(e, 'name')} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="table-display-name"
                                    value={details?.display_name ? details?.display_name : ''}
                                    label="Dispaly Name"
                                    onChange={e => handleChange(e, 'display_name')} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="duration"
                                    autoComplete
                                    options={componentTableDuration}
                                    getOptionLabel={(option) => option.name}
                                    value={details?.dataDuration && details.dataDuration}
                                    type="Schema"
                                    clearable
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Data Duration"
                                            variant="outlined"
                                            required
                                        />
                                    )}
                                    onChange={(e, val) => handleChangeAutocomplete(val, 'dataDuration')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            multiline
                            rows={6}
                            rowsMax={6}
                            value={details.description}
                            id="outlined-required"
                            label="Table Description"
                            variant="outlined"
                            fullWidth
                            autoComplete='off'
                            onChange={e => handleChange(e, 'description')}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <Autocomplete
                            id="object"
                            autoComplete
                            options={objects}
                            getOptionLabel={(option) => option.name}
                            value={details?.object && details.object}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Object"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'object')}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <Autocomplete
                            id="relatedObject"
                            autoComplete
                            options={details?.object ? objects.filter(obj => obj._id !== details?.object?._id) : objects}
                            getOptionLabel={(option) => option.name}
                            value={details?.related_object && details?.related_object}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Related Object"
                                    variant="outlined"
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'related_object')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <p>Table Heading Orders :</p> */}
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="table">
                                {(provided) => (
                                    <div style={{ marginTop: 0 }} className={classNames("material-table", classes.tableNoteStyles)} {...provided.droppableProps} ref={provided.innerRef}>
                                        <MaterialTable
                                            title="Elements"
                                            columns={column}
                                            data={sortingElements}
                                            options={{
                                                search: false,
                                                actionsColumnIndex: -1,
                                                showTitle: false,
                                                addRowPosition: 'first',
                                                emptyRowsWhenPaging: false,
                                                paging: false,
                                                rowStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                },
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <Edit style={{ fontSize: "20px" }} />,
                                                    tooltip: "edit",
                                                    onClick: (event, rowData) => {
                                                        setSelectedElement(rowData);
                                                        setOpenElementDialog(true);
                                                    },
                                                },
                                                {
                                                    icon: () => <Delete style={{ fontSize: "20px" }} />,
                                                    tooltip: "delete",
                                                    onClick: (event, rowData) => handleElementSortingDelete(rowData),
                                                },
                                            ]}
                                            // editable={{
                                            //     onRowAdd: newData =>
                                            //         new Promise((resolve, reject) => {
                                            //             if (Object.keys(newData).length > 0) {
                                            //                 handleElementSortingCreate(newData, resolve, reject)
                                            //                 return;
                                            //             } else {
                                            //                 reject();
                                            //                 return;
                                            //             }
                                            //         }),
                                            //     onRowUpdate: (newData, oldData) =>
                                            //         new Promise((resolve, reject) => {
                                            //             if (Object.keys(newData).length > 0) {
                                            //                 handleElementSortingUpdate(newData, oldData, resolve, reject)
                                            //                 return;
                                            //             } else {
                                            //                 reject();
                                            //                 return;
                                            //             }
                                            //         }),
                                            //     onRowDelete: oldData =>
                                            //         new Promise((resolve, reject) => {
                                            //             handleElementSortingDelete(oldData, resolve, reject)
                                            //             return;
                                            //         })
                                            // }}
                                            components={{
                                                Row: (props) => (
                                                    <>
                                                        <Draggable
                                                            key={props.data?.elementId}
                                                            draggableId={props.data?.elementId?.toString()}
                                                            index={props.index}
                                                        >
                                                            {(provided) => (
                                                                <TableRow
                                                                    style={{ width: '100%' }}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <TableCell style={{ minWidth: 100 }}>
                                                                        <Button className={classes.btn}>
                                                                            {sortingElements.indexOf(props.data) + 1}
                                                                        </Button>
                                                                    </TableCell>
                                                                    <TableCell style={{ minWidth: 250 }}>
                                                                        {props.data?.elementName}
                                                                    </TableCell>
                                                                    <TableCell style={{ minWidth: 100 }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <IconButton aria-label="delete" className={classes.margin}
                                                                                style={{ marginRight: 5 }}
                                                                                onClick={() => {
                                                                                    setSelectedElement(props.data);
                                                                                    setOpenElementDialog(true);
                                                                                }}>
                                                                                <Edit style={{ fontSize: "20px" }} />
                                                                            </IconButton>
                                                                            <IconButton aria-label="delete" className={classes.margin}
                                                                                onClick={() => {
                                                                                    handleElementSortingDelete(props.data);
                                                                                }}>
                                                                                <Delete style={{ fontSize: "20px" }} />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Draggable></>
                                                ),
                                                Toolbar: props => (
                                                    <div>
                                                        <MTableToolbar {...props} />
                                                        <EnhancedTableToolbar {...props} />
                                                    </div>
                                                ),
                                            }}

                                        />
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                    <Grid item xs={12}>
                        {sortingElements.length > 0 &&
                            <div>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => setIsOpenPreviewDailog(true)}
                                >
                                    Preview
                                </Link>
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
            {
                openElementDialog && (
                    <ElementOrderingDialog
                        open={openElementDialog}
                        objectElementsList={objectElementsList}
                        handleDialog={handleElementDialog}
                        selectedElement={selectedElement}
                        sortingElements={sortingElements}
                        handleElementSortingCreate={handleElementSortingCreate}
                        handleElementSortingUpdate={handleElementSortingUpdate}
                    />
                )
            }
            {isOpenPreviewDailog &&
                <SettingsTablePreview
                    open={isOpenPreviewDailog}
                    handleClose={closePreviewDailog}
                    sortingElements={sortingElements}
                    details={details}
                />
            }
        </>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(TableSettings));
