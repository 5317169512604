import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, IconButton, DialogContent, Grid, Typography, TextField, InputBase, fade, DialogActions, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { setLoading } from "../../ducks/loading";
import { Clear, Close, Search } from "@material-ui/icons";
import { Mention, MentionsInput } from "react-mentions";
import { Autocomplete } from "@material-ui/lab";
import { expressionsList } from "./TransactionStepper";
import mentionsInputStyle from "../mentionsInputStyle";


const useStyles = makeStyles(theme => ({
    root: {
        height: "auto",
        padding: '10px 25px'
    },
    dialogPaper: {
        width: '85%',
        height: '85vh'
    },
    dialogContent: {
        padding: 0,
        '& .MuiFormControl-root label': {
            top: '0px'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
        textTransform: 'capitalize'
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    mentionInputRoot: {
        position: 'relative',
        '& .mention-input__suggestions': {
            zIndex: '10 !important',
            height: '200px',
            overflow: 'auto'
        },
        '& .mention-input__highlighter': {
            display: 'none',
            minHeight: '70px !important',
        },
        '& .mention-input__input': {
            border: '1px solid #e6e6e6 !important',
            borderRadius: '3px !important',
            minHeight: '70px !important',
            fontSize: '13px !important',
            width: '100% !important',
            position: 'initial !important'
        }
    },
    textPlace: {
        fontSize: '12px',
        color: 'dimgrey'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    search: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: 20,
        backgroundColor: "#F1F2F5",
        "&:hover": {
            backgroundColor: fade("#F1F2F5", 0.75),
        },
        width: 300,
        marginBottom: 10
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#C4C4C4",
    },
    searchInputRoot: {
        color: "inherit",
        width: "100%",
    },
    searchInputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        height: 20,
        fontSize: 12,

    },
}));


const ExpressionBuilderDialog = ({ open, handleClose, readOnly = false, autoFocus = null, attributes, expressionMessage, setExpressionMessage, handleChangeExpressionMessage, expressionElements, handleAddExpressionUsers, expressionClick, handleExpressionSubmit, handleExpressionClearData, handleAppendExpElement }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const commentRef = React.useRef();

    const dubExpression = expressionElements.filter((el) =>
        el.key.toLowerCase().includes(expressionMessage?.search.toLowerCase())
    )

    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(true)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(true)}
                className={classes.dialogTitle}
            >
                Expression Builder
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(true)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ width: '100%' }} className={classes.mentionInputRoot}>
                                <MentionsInput
                                    readOnly={readOnly}
                                    placeholder="Use @ to mention tags"
                                    inputRef={commentRef}
                                    value={expressionMessage?.body}
                                    onChange={e => handleChangeExpressionMessage(e, 'body')}
                                    allowSuggestionsAboveCursor
                                    style={mentionsInputStyle}
                                    rows={2}
                                    maxLength={1500}
                                    autoFocus={autoFocus}
                                    className="mention-input"
                                >
                                    <Mention
                                        trigger="@"
                                        data={expressionElements && [...expressionElements]}
                                        markup="@@___display__@_"
                                        appendSpaceOnAdd
                                        displayTransform={(display) => `@${display}`}
                                        onAdd={(id, display) => handleAddExpressionUsers(id, display)}
                                    />
                                </MentionsInput>
                                <div>
                                    {expressionMessage?.length !== 0 && <span className={classes.textPlace}>Use @ to mention tags</span>}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                {expressionsList.map((exp, index) =>
                                    <div key={exp.id} onClick={() => { expressionClick(exp) }} style={{ padding: '5px 25px', cursor: 'pointer' }}>
                                        <Typography variant="p" component="p" title={exp?.hoverText}>
                                            {exp?.name}
                                        </Typography>
                                    </div>
                                )
                                }
                            </div>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <div style={{ width: '100%' }}>

                            </div>
                        </Grid>
                        <Grid item md={9} xs={12}>
                            <div className="" style={{ border: '1px solid #e6e6e6', maxHeight: 271, minHeight: 271, borderRadius: 3, overflow: 'auto', padding: '2px 10px' }}>
                                <label style={{ fontSize: 11 }}>Expression Elements</label>
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <Search />
                                    </div>
                                    <InputBase
                                        placeholder="Search players"
                                        classes={{
                                            root: classes.searchInputRoot,
                                            input: classes.searchInputInput,
                                        }}
                                        inputProps={{ "aria-label": "search" }}
                                        value={expressionMessage.search}
                                        onChange={(e) => handleChangeExpressionMessage(e, 'search')}
                                    />
                                    {expressionMessage.search && (
                                        <Clear
                                            onClick={() => setExpressionMessage({ ...expressionMessage, search: '' })}
                                            style={{ padding: "0px 12px", opacity: 0.6, cursor: "pointer" }}
                                        />
                                    )}
                                </div>
                                <div className="" style={{ maxHeight: 200, overflow: 'auto' }}>
                                    {
                                        dubExpression.map((exp, index) =>
                                            <div key={index} style={{ padding: 5, color: "dimgray" }}>
                                                <Typography onClick={e => handleAppendExpElement(exp)} style={{ fontSize: 14, cursor: 'pointer' }} variant="p" component="p" title={exp?.name}>
                                                    {exp?.name}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.dialogueActions}>
                    <DialogActions style={{ padding: "10px 25px" }}>
                        <Button
                            onClick={() => handleExpressionClearData(false)}
                            className={classes.footerButton}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleExpressionSubmit()}
                            className={classes.footerButton}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog >
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(ExpressionBuilderDialog));
