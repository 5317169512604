import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading } from '../ducks/loading';
import SubMenuBar from '../components/SubMenuBar';
import { ReactComponent as BuzzIcon } from "../assets/icons/folder-open.svg";
import Events from './Events';
import Category from './Category';
import EventAttributes from './EventAttributes';
import LeagueFunctions from './LeagueFunctions';
import LeagueIndustries from './LeagueIndustries';
import { ReactComponent as GameMenuIcon } from "../assets/icons/game-menu.svg";
import MasterKpi from './MasterKpi';
import Templates from './Templates';
import { ReactComponent as BuzzTemplate } from "../assets/icons/buzz Linear.svg";
import { ReactComponent as Industries } from "../assets/icons/industry Linear.svg";
import { ReactComponent as Functions } from "../assets/icons/function linear.svg";
import { ReactComponent as CategorysIcon } from "../assets/icons/category.svg";
import { ReactComponent as EventsIcon } from "../assets/icons/event.svg";
import { ReactComponent as AttributesIcon } from "../assets/icons/attribute.svg";
import { ReactComponent as TemplatesIcon } from "../assets/icons/template.svg";
import { ReactComponent as CustomObject } from "../assets/icons/box-tick.svg";
import { ReactComponent as LanguageIcon } from '../assets/icons/translate 1.svg'
import CustomObjects from './CustomObjects';
import ObjectElements from './ObjectElements';
import LanguageMaster from './LanguageMaster';
import ViewALLMasterKPis from './ViewALLMasterKPis';
import CustomElements from './Custom-Elements';
import BusinessUnit from './Business Unit';
import TransactionTables from './Transaction Table/index'
import TransactionStepper from './Transaction Table/TransactionStepper';


const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: '0 35px 20px',
        padding: '0 20px',
        marginTop: '1rem'
    },
    sidebarRoot: {
        backgroundColor: '#FFF',

        '& .title': {
            margin: 0,
            padding: '14px 18px',
            color: '#838383',
            fontSize: 13,
            fontWeight: 600,
            borderBottom: '1px solid #f0f2f5'
        }
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        minWidth: 100,
        color: '#838383'
    },
    tabIndicator: {
        height: '3px',
    },
}))

const MenuOptions = [
    {
        label: 'Buzz Settings', value: 'buzz_settings', icon: <BuzzTemplate style={{ width: 21, height: 21 }} />, pathname: 'buzz_settings', submenubar: true,
        submenu: [
            { label: 'Event Category', value: 'category', icon: <CategorysIcon style={{ width: 21, height: 21 }} />, pathname: 'category' },
            { label: 'Events', value: 'events', icon: <EventsIcon style={{ width: 21, height: 21 }} />, pathname: 'events' },
            { label: 'Event Attributes', value: 'attributes', icon: <AttributesIcon style={{ width: 21, height: 21 }} />, pathname: 'attributes' },
            { label: 'Event Templates', value: 'templates', icon: <TemplatesIcon style={{ width: 21, height: 21 }} />, pathname: 'templates' },
        ]
    },
    { label: 'Industry Master', value: 'industries', icon: <Industries style={{ width: 21, height: 21 }} />, pathname: 'industries' },
    { label: 'Function Master', value: 'functions', icon: <Functions style={{ width: 21, height: 21 }} />, pathname: 'functions' },
    { label: 'Kpi Master', value: 'masterkpi', icon: <GameMenuIcon style={{ width: 21, height: 21 }} />, pathname: 'masterkpi' },
    { label: 'Transaction Data', value: 'transactiondata', icon: <GameMenuIcon style={{ width: 21, height: 21 }} />, pathname: 'transactiondata' },
    { label: 'Custom Elements', value: 'customelements', icon: <CustomObject style={{ width: 21, height: 21 }} />, pathname: 'customelements' },
    { label: 'Business Unit', value: 'businessunit', icon: <Industries style={{ width: 21, height: 21 }} />, pathname: 'businessunit' },
    { label: 'Custom Objects', value: 'customobjects', icon: <CustomObject style={{ width: 21, height: 21 }} />, pathname: 'customobjects' },
    { label: 'Language Master', value: 'languagemaster', icon: <LanguageIcon style={{ width: 21, height: 21 }} />, pathname: 'languagemaster' }
]

const PlatformAdmin = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex' }}>
                <SubMenuBar menuOptions={MenuOptions} basepath={path} {...props} from='buzz_settings' />
                <div id="master-data-list" style={{ width: '100%', overflowY: 'auto', height: 'calc(100vh - 60px)' }}>
                    <Switch>
                        <Route path={`${path}/masterkpi`} exact>
                            <MasterKpi />
                        </Route>
                        <Route path={`${path}/buzz_settings/category`} exact>
                            <Category />
                        </Route>
                        <Route path={`${path}/buzz_settings/events`} exact>
                            <Events />
                        </Route>
                        <Route path={`${path}/buzz_settings/attributes`} exact>
                            <EventAttributes />
                        </Route>
                        <Route path={`${path}/buzz_settings/templates`} exact>
                            <Templates />
                        </Route>
                        <Route path={`${path}/industries`}>
                            <LeagueIndustries />
                        </Route>
                        <Route path={`${path}/functions`}>
                            <LeagueFunctions />
                        </Route>
                        <Route path={`${path}/customobjects`} exact>
                            <CustomObjects />
                        </Route>
                        <Route path={`${path}/customelements`} exact>
                            <CustomElements />
                        </Route>
                        <Route path={`${path}/customobjects/:objId`} exact>
                            <ObjectElements />
                        </Route>
                        <Route path={`${path}/languagemaster`} exact>
                            <LanguageMaster />
                        </Route>
                        <Route path={`${path}/viewallmasterkpis`} exact>
                            <ViewALLMasterKPis />
                        </Route>
                        <Route path={`${path}/businessunit`}>
                            <BusinessUnit />
                        </Route>
                        <Route path={`${path}/transactiondata`} exact>
                            <TransactionTables />
                        </Route>
                        <Route path={`${path}/transactiondata/new`} component={TransactionStepper} exact />
                        <Route path={`${path}/transactiondata/:tableId`} component={TransactionStepper} exact />
                        <Redirect to={`${path}/buzz_settings/category`} exact />
                    </Switch>
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value))
    };
};
export default connect(null, mapDispatchToProps)(PlatformAdmin);
